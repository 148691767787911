import React from "react";
import FormCard from "../../reusables/cards/FormCard";
import styled from "styled-components";
import { GoCheckCircleFill } from "react-icons/go";
import { useSelector } from "react-redux";
import web3 from "../../../methods/web3setup";
import { theme } from "../../../theme";

const SuccessImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px;
  background-color: ${theme.whiteSmoke};
  border-radius: 10px;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SuccessIcon = styled(GoCheckCircleFill)`
  font-size: 100px;
  color: ${theme.secondary};
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 80px;
  }
`;

const SuccessText = styled.div`
  font-size: 22px;
  text-align: center;
  color: ${theme.gray5};
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const DetailHeaderText = styled.div`
  font-size: 24px;
  text-align: center;
  color: ${theme.gray5};
  margin: 20px 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 15px 0;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  gap: 15px;
`;

const TransactionHash = styled.a`
  display: block;
  text-decoration: underline;
  color: ${theme.primary};
  margin-bottom: 5px;
  white-space: nowrap;

  &:hover {
    color: ${theme.blue};
  }

  @media (max-width: 768px) {
    /* Truncate hash on mobile view */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 200px; /* Adjust to fit your design */
    display: inline-block;
  }
`;

const truncateHash = (hash, isMobile) =>
  isMobile ? `${hash.slice(0, 6)}...${hash.slice(-6)}` : hash;

const SuccessPage = ({ setActiveStep }) => {
  const state = useSelector((state) => state.multisendReducer);
  const user = useSelector((state) => state.persistedStore);

  const results = state?.blockchainResponse || []; // Assuming this contains the list of results

  const transactionHashes = results.map((res) => res.result.transactionHash);
  const blockNumbers = results.map((res) => res.result.blockNumber).join(", ");
  const totalGasUsed = results.reduce(
    (total, res) =>
      total +
      parseFloat(
        web3.utils.fromWei(res.result.effectiveGasPrice.toString(), "ether")
      ),
    0
  );

  const payAgain = () => {
    window.location.reload();
  };

  const isMobile = window.innerWidth <= 768; // Check if it's mobile view


  return (
    <FormCard>
      <SuccessImageContainer>
        <SuccessIcon />
        <SuccessText>
          Assets have been deposited to their respective addresses.
        </SuccessText>
        <DetailHeaderText>Transaction Details</DetailHeaderText>
        <DetailContainer>
          <div>
            <strong>Hashes:</strong>
            <div>
              {transactionHashes.map((hash, index) => (
                <TransactionHash
                  key={index}
                  href={`${user.network.blockExplorerUrls}${hash}`}
                  target="_blank"
                >
                  {truncateHash(hash, isMobile)}
                </TransactionHash>
              ))}
            </div>
          </div>
          <div>
            <strong>Block Numbers:</strong>
            <div>{blockNumbers}</div>
          </div>
          <div>
            <strong>Total Gas Used:</strong>
            <div>{totalGasUsed} Ether</div>
          </div>
        </DetailContainer>
        <button
          className="nav-item mx-2 navbar-btn p-0 nav-link"
          style={{ width: "300px", marginTop: "10px" }}
          onClick={() => payAgain()}
        >
          Initiate Another Transfer
        </button>
      </SuccessImageContainer>
    </FormCard>
  );
};

export default SuccessPage;
