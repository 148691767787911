import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  color: white;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
  }

  @media (min-width: 1200px) {
    padding: 0 140px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 760px;
  height: auto;

  img {
    max-width: 100%;
    height: auto;
    z-index: 99;
  }
`;

const AchievementsText = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding-left: 20%;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.greenLight};
  font-size: 36px;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.blue8};
  text-align: center;

  @media (min-width: 768px) {
    font-size: 32px;
    text-align: left;
  }
`;

const Stat = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.blue8};
  width: 100%;
  text-align: center;
  margin: 10px 0;

  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const StatTitle = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.blue8};
  margin-top: 10px;
`;

const StatValue = styled.p`
  font-size: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.black};

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const Achievements = () => (
  <Container>
    <ImageContainer>
      <img src="/assets/img/PNG/Landing_pic2.svg" alt="Achievements graphic" />
    </ImageContainer>
    <AchievementsText>
      <Title>Achievements</Title>
      <Subtitle>Helping thousands of customers achieve their goals</Subtitle>
      <Stat>
        <StatTitle>Total Payment Sent</StatTitle>
        <StatValue>1000 M+</StatValue>
      </Stat>
      <Stat>
        <StatTitle>Total User</StatTitle>
        <StatValue>50k +</StatValue>
      </Stat>
      <Stat>
        <StatTitle>Companies</StatTitle>
        <StatValue>5k +</StatValue>
      </Stat>
    </AchievementsText>
  </Container>
);

export default Achievements;
