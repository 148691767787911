import approvePayment from "../../methods/contracts/approve";
import tokenDecimals from "../../methods/contracts/decimals";
import transactionFee from "../../methods/contracts/getTransactionFee";
import _const from "../types";
import { setRequestStatus, updatePayment } from "./payrollActions";
import { toggleModal } from "./generalActions";
import swal from "sweetalert";
import { bulkSend, bulkSendNative } from "../../methods/contracts/payRecipients";

export const payPayrollRecipients = (
  amounts,
  addresses,
  batchId,
  currencyAddress,
  walletAddress,
  payrollId,
  blockchain
) =>{
  return async dispatch => {
    try {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: true
      });

      const fee = await transactionFee(amounts, addresses);
      const decimals = await tokenDecimals(currencyAddress);
      const approve = await approvePayment(
        amounts,
        currencyAddress,
        walletAddress,
        decimals,
        fee
      );
      
      dispatch(setRequestStatus(payrollId, 1));
      if (approve) {
        const res = await bulkSend(
          amounts,
          addresses,
          batchId,
          walletAddress,
          decimals,
          currencyAddress,
          fee
        );
        if (res.status) {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
          dispatch(setRequestStatus(payrollId, 3));
          const persistedStoreString = localStorage.getItem('persist:persistedStore');
          const persistedStoreObject = JSON.parse(persistedStoreString);
          const userString = persistedStoreObject.user;
          const userObject = JSON.parse(userString);
          console.log(res, "response from the chain")
          dispatch(updatePayment(userObject.id, res.transactionHash, payrollId,blockchain));
          dispatch(toggleModal(20));
          // swal({
          //   title: "Transaction Successful!",
          //   text: "",
          //   icon: "success",
          //   dangerMode: false
          // });
          dispatch({
            type: _const.SUCCESSFUL_PAYMENT,
            payload: res
          });
        } else {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
          dispatch(setRequestStatus(payrollId, 2));
          // dispatch(toggleModal(20));
          // swal({
          //   title: "Transaction Failed!",
          //   text: "",
          //   icon: "warning",
          //   dangerMode: true
          // });
        }
        if (!approve.status) {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
          dispatch(setRequestStatus(payrollId, 2));
          swal({
            title: "Transaction Failed!",
            text: "",
            icon: "warning",
            dangerMode: true
          });
        }
      }
    } catch (err) {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: false
      });

      throw err;
    }
  };
}

export const payTokenMultiRecipients =(
  amounts,
  addresses,
  batchId,
  currencyAddress,
  walletAddress,
  decimals,
  transFee,
  network,
) =>{
  return async dispatch => {
    console.log(currencyAddress)
    try {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: true
      });
      let fee = transFee
      // if (!fee){
      //    fee = await transactionFee(amounts, addresses, network.chainId);
      // }
      if (!decimals){
         decimals = await tokenDecimals(currencyAddress);
      }
      const approve = await approvePayment(
        amounts,
        currencyAddress,
        walletAddress,
        decimals,
        network
      );
      if (approve) {
        const res = await bulkSend(
          amounts,
          addresses,
          batchId,
          walletAddress,
          decimals,
          currencyAddress,
          fee,
          network
        );
        if (res[0].status) {
          dispatch({
            type: _const.SUCCESSFUL_PAYMENT,
            payload: res
          });
        } else {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
        }
        if (!approve.status) {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
          swal({
            title: "Transaction Failed!",
            text: "",
            icon: "warning",
            dangerMode: true
          });
        }
      }
    } catch (err) {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: false
      });

      throw err;
    }
  };
}
export const payNativeMultiRecipients = (
  amounts,
  addresses,
  batchId,
  walletAddress,
  decimals,
  transFee,
  network
  
) =>{
  return async dispatch => {
    try {
      
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: true
      });
      let fee = transFee
      console.log(network.chainId, "koasjdkjsk")
      // if (!fee){
      //    fee = await transactionFee(amounts, addresses, network);
      // }
        const res = await bulkSendNative(
          amounts,
          addresses,
          batchId,
          walletAddress,
          fee,
          network
          
        );
        if (res[0].status) {
          dispatch({
            type: _const.SUCCESSFUL_PAYMENT,
            payload: res
          });
        } else {
          dispatch({
            type: _const.APPROVING_PAYMENT,
            payload: false
          });
        }
      
    } catch (err) {
      dispatch({
        type: _const.APPROVING_PAYMENT,
        payload: false
      });

      throw err;
    }
  };
}
