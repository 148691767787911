import styled from "styled-components";
import { theme } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
  width: 60%;

  @media (max-width: 768px) {
    width: 80%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;

  .flex {
    width: 100%;
    display: flex;
    gap: 15px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  align-self: center;

  @media (max-width: 768px) {
    width: 100%;
    /* align-items: center; */
  }
`;

export const ButtonContainer2 = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  justify-content: space-between;
  margin: 30px 0;
  align-self: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 818px;
  gap: 16px;

  @media (max-width: 1300px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const DetailCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 88px;
  padding: 7px 14px 7px 14px;
  /* gap: 12px; */
  border-radius: 12px;
  border: 1px solid #ffffff14;
  background: #ffffff0a;

  @media (max-width: 1300px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const Prop = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #6c82a4;
`;

export const Value = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: ${({ theme }) => theme.black};
`;

export const TextLabel = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: ${({ theme }) => theme.black};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Description = styled.p`
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #ffffff14;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const OutlinedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 286px;
  height: 56px;
  border-radius: 51px;
  border: 1px solid #ffffff14;
  background: #ffffff0a;

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FilledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 286px;
  height: 56px;
  border-radius: 51px;
  border: 1px solid #ffffff14;
  background-color: ${({ theme }) => theme.greenLight};
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 1px solid #38d8ab;

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FilledButton2 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  border-radius: 51px;
  border: 1px solid #ffffff14;
  background-color: ${({ theme }) => theme.greenLight};
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 1px solid #38d8ab;
  color: ${({ theme }) => theme.black};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
    color: ${({ theme }) => theme.black};
  }
`;

export const TransactionHashSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 396px;
  padding: 10px;
  gap: 10px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const TransactionHashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 10px;
  background: #ffffff0a;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  margin-bottom: 30px;
`;

export const RewardDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 649px;
  gap: 300px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const RewardDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
`;

export const RewardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px 7px 14px;
  border-bottom: 1px solid #ffffff14;

  .prop {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #6c82a4;
  }

  .value {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: right;
    color: ${({ theme }) => theme.black};
  }

  @media (max-width: 768px) {
    .prop {
      font-size: 18px;
    }
    .value {
      font-size: 16px;
    }
  }
`;

export const UploadCSVButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: auto;
  height: 43px;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.greenLight};
  /* background: ${({ theme }) => theme.greenLight}; */
  color: ${({ theme }) => theme.greenLight};

  svg {
    font-size: 24px;
    color: ${({ theme }) => theme.greenLight};
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #ffffff1f;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.greenLight};
    }
  }

  .active-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.greenLight};
    background-color: ${({ theme }) => theme.greenLight};
    border-radius: 50%;
    cursor: pointer;
  }
  /* 
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  } */
`;

export const DetailsSummaryContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const DetailsSummary = styled.div`
  width: 269.65px;
  border-bottom: 1px solid #ffffff14;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  .prop {
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    color: ${({ theme }) => theme.black};
  }

  .value {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    color: ${({ theme }) => theme.black};
  }
`;

export const ResponsiveFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const TokenBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20%;
`;

export const TokenBalance = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: right;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
  }
`;

export const NativeTokenTogglerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
`;

export const GenerateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  width: auto;
  height: 45px;
  border-radius: 51px;
  border: 1px solid ${({ theme }) => theme.green1};
  background-color: ${({ theme }) => theme.greenLight};
  padding: 10px;
  outline: none;
  color: ${({ theme }) => theme.black};

  &:hover {
    background-color: ${({ theme }) => theme.greenDark};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DetailHeaderText = styled.div`
  font-size: 24px;
  text-align: center;
  color: ${theme.gray5};
  margin: 20px 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 15px 0;
  }
`;

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
  width: 396px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
    column-gap: 0;
  }
`;

export const DetailLabel = styled.div`
  font-weight: bold;
  color: #555;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DetailText = styled.div`
  word-wrap: break-word;
  color: ${theme.gray2};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const TransactionHash = styled.a`
  text-decoration: underline;
  color: ${theme.primary};

  &:hover {
    color: ${theme.blue};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
