/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles.css";
import FormCard from "../../../reusables/cards/FormCard";
import BalanceCard from "../../../reusables/cards/BalanceCard";
import { useSelector, useDispatch } from "react-redux";

import {
  calculateTotalAmount,
  formatToFourDecimalPlaces,
} from "../../../../methods/helper";
import ErrorMessage from "../../../reusables/MessagePopups/ErrorMessage";
import transactionFee from "../../../../methods/contracts/getTransactionFee";
import {
  payTokenMultiRecipients,
  payNativeMultiRecipients,
} from "../../../../redux/actions/payusers";
import { useTheme } from "styled-components";
import { Loader } from "../../../loader";
const ApproveForm = ({
  handleNextStep,
  handlePrevStep,
  walletBalance,
  chainId,
  sendNativeToken,
  setSendNativeToken,
  network,
}) => {
  const state = useSelector((state) => state.multisendReducer);
  const [totalAmout, setTotalAmount] = useState(0);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false); // New state for loading
  const [networkDecimals, setNetworkkDecimals] = useState();
  const [transFee, setTransFee] = useState();
  const [tokenAddress, setTokenAddress] = useState();
  const dispatch = useDispatch();
  const theme = useTheme();



  const amounts = state?.receiversAddress?.map((x) => {
    return Number(x.amount);
  });
  const addresses = state?.receiversAddress?.map((x) => {
    return x.address;
  });
  const batchId = Date.now(); // Using the current timestamp as a simple batchId

  const handleMultiSend = async () => {
    setLoading(true); // Start loading

    const walletAddress = state?.senderAddress;
    const decimals = state?.tokenAddress?.decimal;
    const currencyAddress = state?.tokenAddress?.tokenAddress;

    if (sendNativeToken) {
      if (
        state.senderWalletBallance.nativeTokenBalance <
        totalAmout + Number(transFee.totaltransactionFee)
      ) {
        setErrors([
          "Not enough tokens. Please add funds to your token balance or use a smaller batch",
        ]);
        setLoading(false); // Stop loading on error
        return;

      }
      dispatch(
        payNativeMultiRecipients(
          amounts,
          addresses,
          batchId,
          walletAddress,
          decimals,
          transFee,
          network
        )
      );
    } else {
      if (
        state.senderWalletBallance.nativeTokenBalance <=
        Number(transFee.totaltransactionFee)
      ) {
        setErrors([
          "Not enough tokens for gas. Please add funds to your token balance or use a smaller batch",
        ]);
        setLoading(false); // Stop loading on error
        return;

      }
      if (tokenAddress?.balance < totalAmout) {
        setErrors([
          "Not enough tokens. Please add funds to your token balance or use a smaller batch",
        ]);
        setLoading(false); // Stop loading on error
        return;

      }
      dispatch(
        payTokenMultiRecipients(
          amounts,
          addresses,
          batchId,
          currencyAddress,
          walletAddress,
          decimals,
          transFee,
          network
        )
      );
    }
  };

  useEffect(() => {
    if (sendNativeToken && !state.tokenAddress) {
      setTokenAddress({
        decimals: network?.nativeCurrency?.decimals,
        symbol: network?.nativeCurrency?.symbol,
        balance: walletBalance,
      });
      const total = calculateTotalAmount(state?.receiversAddress);
      setTotalAmount(total);
    }

    if (state?.blockchainResponse) {
      handleNextStep();
    }
  }, [state]);

  useEffect(() => {
    if (state.tokenAddress) {
      setTokenAddress(state.tokenAddress);
      const total = calculateTotalAmount(state?.receiversAddress);
      console.log("total" + total, "balance" + tokenAddress?.balance);
      if (Number(total) > Number(tokenAddress?.balance)) {
        setErrors([
          "Not enough tokens. Please add funds to your token balance or use a smaller batch",
        ]);
      }
      setTotalAmount(total);
    }
  }, [tokenAddress]);

  useEffect(() => {
    const fetchTransactionFee = async () => {
      console.log(amounts, addresses, network);
      const fee = await transactionFee(amounts, addresses, network);
      setTransFee(fee);
    };
    fetchTransactionFee();
  }, [networkDecimals]);

  return (
    <FormCard>
      <div className="token_balance_container"></div>
      <div className="balance_area">
        <BalanceCard
          balanceTitle={"Transaction fee"}
          balanceValue={
            transFee?.totaltransactionFee +
            " " +
            state?.senderWalletBallance?.nativeTOkenName
          }
        />
        <BalanceCard
          balanceTitle={"Total number of addresses"}
          balanceValue={addresses?.length}
        />
        {totalAmout && (
          <BalanceCard
            balanceTitle={"Total number of tokens to send"}
            balanceValue={
              formatToFourDecimalPlaces(totalAmout) + " " + tokenAddress?.symbol
            }
          />
        )}

        {sendNativeToken ? (
          <BalanceCard
            balanceTitle={`Your ${tokenAddress?.symbol} balance`}
            balanceValue={
              formatToFourDecimalPlaces(tokenAddress?.balance) +
              " " +
              tokenAddress?.symbol
            }
          />
        ) : (
          <>
            {console.log(sendNativeToken)}
            <BalanceCard
              balanceTitle={`Your ${tokenAddress?.symbol} balance`}
              balanceValue={
                formatToFourDecimalPlaces(tokenAddress?.balance) +
                " " +
                tokenAddress?.symbol
              }
            />
            <BalanceCard
              balanceTitle={`Your ${state?.senderWalletBallance?.nativeTOkenName} balance`}
              balanceValue={
                formatToFourDecimalPlaces(
                  state?.senderWalletBallance?.nativeTokenBalance
                ) +
                " " +
                state?.senderWalletBallance?.nativeTOkenName
              }
            />
          </>
        )}
      </div>
      <div>
        <div className="token_balance_container">
          <span>List of recipients</span>
        </div>
        <div
          className="list_Of_address"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          {state.receiversAddress?.map((receiver, index) => (
            <div key={index} className="list_item_address">
              <span>{receiver?.address}</span>
              <span>
                {receiver?.amount} {tokenAddress?.symbol}
              </span>
            </div>
          ))}
        </div>
      </div>

      {errors && (
        <ErrorMessage
          message={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "red",
              }}
            >
              {errors?.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </div>
          }
        />
      )}

      <div className="button_container">
        <button
          // variant="primary"
          className="mt-3 button"
          onClick={() => handlePrevStep()}
          style={{ background: theme.greenLight, color: theme.black }}
        >
          Back
        </button>
        <button
          // variant="primary"
          type="submit"
          className="mt-3 button"
          onClick={handleMultiSend}
          style={{ background: theme.greenLight, color: theme.black }}
          disabled={loading ||errors}
        >
         {loading ? 
             <Loader />
          : 
            "Proceed"
          }
        </button>
      </div>
    </FormCard>
  );
};

export default ApproveForm;
