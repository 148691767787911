import createContract from "./createContract";
import payrollAbi from "../forwarderproxy.json";
import erc20Abi from "../erc20.json";

import transactionFee from "../../methods/contracts/getTransactionFee";

import web3 from "web3";


export async function bulkSend(
  amounts,
  addresses,
  batchId,
  walletAddress,
  decimals,
  currencyAddress,
  fee,
  network
) {
  try {
    const results = [];
    const BATCH_SIZE = 200; // Define batch size
    const totalBatches = Math.ceil(addresses.length / BATCH_SIZE);
    const WEB3 = new web3(window.ethereum);

    // Convert decimals to BigInt factor
    const num = BigInt(Math.pow(10, Number(decimals)));

    // Map amounts to BigInt strings
    const amountRecordsToSend = amounts.map((x) =>
      (num * BigInt(Math.floor(x * 10 ** Number(decimals))) / BigInt(10 ** Number(decimals))).toString()
    );

    // Create an ERC-20 contract instance
    const erc20Contract = new WEB3.eth.Contract(erc20Abi, currencyAddress); // Replace `erc20Abi` with your ERC-20 ABI

    for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
      const start = batchIndex * BATCH_SIZE;
      const end = Math.min(start + BATCH_SIZE, addresses.length);

      const batchAddresses = addresses.slice(start, end);
      const batchAmounts = amountRecordsToSend.slice(start, end).map(BigInt); // Ensure BigInt conversion
      const batch = batchIndex === 0 ? batchId : batchId - batchIndex;

      // Check allowance
      const allowance = await erc20Contract.methods
        .allowance(walletAddress, network.FORWARDER_ADDRESS)
        .call();
      console.log(`Batch ${batchIndex + 1} Allowance: `, allowance);

      // Calculate fee for the batch
      const currentBatchFee = await transactionFee(
        batchAmounts.map((amt) => Number(amt) / 10 ** Number(decimals)), // Convert BigInt to Number for calculation
        batchAddresses,
        network
      );

      console.log(
        `Batch ${batchIndex + 1} Fee: `,
        currentBatchFee,
        "Total Batch Amount: ",
        batchAmounts.reduce((sum, x) => sum + x, BigInt(0)) // Proper BigInt addition
      );

      try {
        const bulkSenderContract = await createContract(
          payrollAbi,
          network.FORWARDER_PROXY_ADDRESS
        );

        const nonce = await WEB3.eth.getTransactionCount(walletAddress);

        const gasEstimate = await bulkSenderContract.methods
          .distributeToken(batch, currencyAddress, batchAddresses, batchAmounts)
          .estimateGas({
            from: walletAddress,
            value: currentBatchFee.transactionFee,
            nonce: nonce,
          });

        const result = await bulkSenderContract.methods
          .distributeToken(batch, currencyAddress, batchAddresses, batchAmounts)
          .send({
            from: walletAddress,
            value: currentBatchFee.transactionFee,
            gas: gasEstimate,
          });

        console.log(`Batch ${batchIndex + 1} succeeded`, result);
        results.push({ batchIndex: batchIndex + 1, status: true, result });
      } catch (error) {
        console.log(`Batch ${batchIndex + 1} failed`, error);
        results.push({
          batchIndex: batchIndex + 1,
          status: false,
          error: error.message,
        });
      }
    }

    return results; // Return all batch results
  } catch (error) {
    console.error("Error processing batches:", error);
    throw error;
  }
}




export async function bulkSendNative(
  amounts,
  addresses,
  batchId,
  walletAddress,
  fee,
  network
) {
  try {
    const results = [];
    const BATCH_SIZE = 300;
    const totalBatches = Math.ceil(addresses.length / BATCH_SIZE);
    const WEB3 = new web3(window.ethereum);
    console.log(network.nativeCurrency.decimals, "dsjdksjdjk")
    const num = BigInt(Math.pow(10, Number(network.nativeCurrency.decimals)));

    const convertToWei = (value) =>
      (num * BigInt(Math.floor(value * 10 ** 18)) / BigInt(10 ** 18)).toString();

    const amountRecordsToSend = amounts.map(convertToWei);

    for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
      const start = batchIndex * BATCH_SIZE;
      const end = Math.min(start + BATCH_SIZE, addresses.length);

      const batchAddresses = addresses.slice(start, end);
      const batchAmounts = amountRecordsToSend.slice(start, end);
      const batch = batchIndex == 0? batchId: batchId-batchIndex;
      console.log(network.chainId,"chhh")

      // Calculate fee for the batch
      const currentBatchFee = await transactionFee(
        batchAmounts.map((amt) => parseFloat(amt) / 10 ** Number(network.nativeCurrency.decimals)),
        batchAddresses,
        network
      );
      console.log(currentBatchFee,"chhh")
      const batchTotalAmount = batchAmounts.reduce(
        (total, amt) => total + BigInt(amt),
        BigInt(0)
      ) + BigInt(currentBatchFee.transactionFee);

      try {
    const bulkSenderContract = await createContract(
      payrollAbi,
      network.FORWARDER_PROXY_ADDRESS
    );

        const gasEstimate = await bulkSenderContract.methods
          .distributeNativeCoin(batch, batchAddresses, batchAmounts)
          .estimateGas({
            from: walletAddress,
            value: batchTotalAmount.toString(),
          });

        const result = await bulkSenderContract.methods
          .distributeNativeCoin(batch, batchAddresses, batchAmounts)
          .send({
            from: walletAddress,
            value: batchTotalAmount.toString(),
            gas: gasEstimate,
          });

        console.log(`Batch ${batchIndex + 1} succeeded`, result);
        results.push({ batchIndex: batchIndex + 1, status: true, result });
      } catch (error) {
        console.log(`Batch ${batchIndex + 1} failed`, error);
        results.push({
          batchIndex: batchIndex + 1,
          status: false,
          error: error.message,
        });
      }
    }

    return results; // Return all batch results
  } catch (error) {
    console.error("Error processing batches:", error);
    throw error;
  }
}


// export async function bulkSendNative(
//   amounts,
//   addresses,
//   batchId,
//   walletAddress,
//   fee,
//   network,
// ) {
//   try {
//     const bulkSenderContract = await createContract(
//       payrollAbi,
//       network.FORWARDER_PROXY_ADDRESS
//     );
//     const num = BigInt(Math.pow(10, Number(network.nativeCurrency.decimals)));
//     const WEB3 = new web3(window.ethereum);

//     // Convert amounts to integer values in wei
//     const amountRecordsToSend = amounts.map(x => (num * BigInt(Math.floor(x * 10**18)) / BigInt(10**18)).toString());
//     let totalAmount = BigInt(0);

//     for (let i = 0; i < amounts.length; i++) {
//       totalAmount += (num * BigInt(Math.floor(amounts[i] * 10**18)) / BigInt(10**18));
//     }

//     const nonce = await WEB3.eth.getTransactionCount(walletAddress);
//     console.log(fee.transactionFee);

//     // Ensure fee is also an integer and add it to totalAmount
//     totalAmount += BigInt(fee.transactionFee) + BigInt(300000);
//     console.log({ totalAmount: totalAmount.toString() }, nonce);

//     const gasEstimate = await bulkSenderContract.methods
//       .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//       .estimateGas({
//         from: walletAddress,
//         value: totalAmount.toString(),
//         nonce: nonce,
//       });

//     const result = await bulkSenderContract.methods
//       .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//       .send({
//         from: walletAddress,
//         value: totalAmount.toString(),
//         gas: gasEstimate,
//       });

//     console.log({ result });

//     return result;
//   } catch (error) {
//     console.log({ error });

//     return error;
//   }
// }



// export async function bulkSendNative(
//   amounts,
//   addresses,
//   batchId,
//   walletAddress,
//   fee,
//   network,
// ) {
//   try {
//     const bulkSenderContract = await createContract(
//       payrollAbi,
//       network.forwarderProxyAddress
//     );
//     console.log({amounts,
//       addresses,
//       batchId,
//       walletAddress,
//       fee,
//       network})
//     var num = Math.pow(10, Number(network.nativeCurrency.decimals));
//     let WEB3 = new web3(window.ethereum);
//     let amountRecordsToSend = amounts.map((x) => (num * x).toString());
//     let totalAmount = 0
//     for (let i = 0; i < amounts.length; i++) {
//       totalAmount = totalAmount + (amounts[i] * num);
//     }

//     const nonce = await WEB3.eth.getTransactionCount(walletAddress)
//     console.log(fee.transactionFee)
//     totalAmount = totalAmount + Number(fee.transactionFee) + 300000
//     console.log({totalAmount}, nonce)

//     let gasEstimate = await bulkSenderContract.methods
//     .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//     .estimateGas({
//       from: walletAddress,
//       value: totalAmount,
//       nonce: nonce
//     });

//     let result = await bulkSenderContract.methods
//       .distributeNativeCoin(batchId, addresses, amountRecordsToSend)
//       .send({
//         from: walletAddress,
//         value: totalAmount,
//         gas: gasEstimate
//       });

//       console.log({result})

//     return result;
//   } catch (error) { 
//     console.log({error})

//     return error;
//   }
// }
